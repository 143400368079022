import React from "react";
import MessageItem from "./MessageItem";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { upload_image } from "src/utils";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import ImageSelector from "src/components/ImageSelector";
import { useAppContext } from "src/hooks";
import { Iconify } from "src/components";
import { useEffect } from "react";
import { _editMessage, _seenMessages } from "src/DAL";
import { useSnackbar } from "notistack";
import moment from "moment";

function MessageContainer({
  loadingMessageList,
  messageCount,
  loadMoreMessages,
  currentChat,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { messageList, setMessageList, generateUniqueIdForMessage } =
    useAppContext();
  const { socketEmit, dispatch_get_user_profile } = useAppContext();
  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const [targetEditMessage, setTargetEditMessage] = useState();
  ///////////////////////////////Send Message/////////////////////////////////////////////////////////

  const [chatFile, setChatFile] = useState();
  const [newMessageText, setNewMessageText] = useState("");
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const handleSendMessage = async () => {
    if (isSendingMessage || (newMessageText.trim() == "" && !chatFile)) {
      return;
    }
    setIsSendingMessage(true);
    try {
      let chatImg = "";
      if (chatFile) {
        const imgUplaodResult = await upload_image(chatFile);
        chatImg = imgUplaodResult.path;
      }
      let req_data = {
        text: newMessageText,
        image: chatImg,
        message_status: 0,
        chat_id: currentChat._id,
        receiver_id: currentChat.user.map((user) => {
          return user.user_id;
        }),
        temp_id: generateUniqueIdForMessage(),
        chat_type: currentChat.type,
      };
      let tempMessageData = {
        ...req_data,
        audio_duration: "",
        createdAt: moment().toISOString(),
        message_exist: true,
        message_sender: dispatch_get_user_profile(),
        message_type: "general",
        sender_id: dispatch_get_user_profile().user_id,
        _id: "",
      };
      setMessageList((prev) => [tempMessageData, ...prev]);
      socketEmit("sendMessage", {
        ...req_data,
        user_id: dispatch_get_user_profile().user_id,
      });
      setChatFile();
      setNewMessageText("");
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while sending message");
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleSendEditMessage = async (e) => {
    e.preventDefault();
    if (isSendingMessage || (newMessageText.trim() == "" && !chatFile)) {
      return;
    }
    if (!targetEditMessage) {
      return;
    }
    setIsSendingMessage(true);
    try {
      const data = { text: newMessageText };
      const result = await _editMessage(data, targetEditMessage._id);
      if (result.code == 200) {
        socketEmit("editMessage", {
          message_id: targetEditMessage._id,
          user_id: targetEditMessage.sender_id,
          text: newMessageText,
        });
        setNewMessageText("");
        setIsEditingMessage(false);
        enqueueSnackbar("Message Edited successfully", { variant: "success" });
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, "error catched while editing message");
    } finally {
      setIsSendingMessage(false);
    }
  };
  /////////////////////////Text area change////////////////////////////////////////////
  let shift_pressed = false;
  const handleAreaChange = (e) => {
    const { value } = e.target;
    if (isEditingMessage && value.trim() == "") {
      setIsEditingMessage(false);
    }
    if (shift_pressed) {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "deleteWordBackward") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "insertText") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.inputType === "insertFromPaste") {
      setNewMessageText(value);
      return;
    }
    if (e.nativeEvent.data === null) {
      if (e.nativeEvent.inputType === "insertFromPaste") {
        return;
      }
      if (e.nativeEvent.inputType === "deleteWordBackward") {
        return;
      }
      !isEditingMessage && handleSendMessage(e);
      isEditingMessage && handleSendEditMessage(e);
      return;
    }
    setNewMessageText(value);
  };
  const handleAreaKeyUp = (e) => {
    const { key } = e;
    if (key == "Shift") {
      shift_pressed = false;
    }
  };
  const handleAreaKeyDown = (e) => {
    if (e.shiftKey) {
      shift_pressed = true;
    }
  };
  ////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (currentChat) {
      _seenMessages(currentChat._id);
    }
  }, [currentChat]);
  //////////////////////////TEXT AREA EVENT LISTENERS FOR IMAGE///////
  const handlePaste = (e) => {
    let file = e.clipboardData.files[0];
    if (file) {
      if (!file.type.includes("image")) return;
      setChatFile(file);
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    let file = e.dataTransfer.files[0];
    if (file) {
      if (!file.type.includes("image")) return;
      setChatFile(file);
    }
  };
  ////////////////////////////////////////////////////////////////////
  return (
    <div className="chat-messages-parent">
      <div className="messages-container">
        {!loadingMessageList && (
          <>
            {messageList.length > 0 && (
              <div
                id="scrollableDiv"
                className="scroller"
                style={{
                  height: "100%",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                }}
              >
                <InfiniteScroll
                  dataLength={messageList.length}
                  next={() => {
                    loadMoreMessages();
                  }}
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column-reverse",
                  }}
                  inverse={true}
                  hasMore={messageCount > messageList.length}
                  loader={
                    <CircularProgress
                      style={{
                        // position: "absolute",
                        width: "18px",
                        height: "18px",
                        margin: "auto",
                        // left: "40%",
                      }}
                    />
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {[...messageList].map((message, index) => {
                    return (
                      <MessageItem
                        key={message.temp_id ? message.temp_id : message._id}
                        message={message}
                        setIsEditingMessage={setIsEditingMessage}
                        setNewMessageText={setNewMessageText}
                        setTargetEditMessage={setTargetEditMessage}
                        prevMessage={
                          index !== 0 ? messageList[index - 1] : null
                        }
                      />
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
            {messageList.length == 0 && (
              <div className="h-100 d-flex justify-content-center align-items-center">
                <Typography>No messages yet!</Typography>
              </div>
            )}
            <div className="chat-bottom-bar">
              <div className="message-editor">
                <div className="row align-items-center">
                  <div className="col-11 pr-0">
                    <div className="text-area-parent">
                      {chatFile && (
                        <div
                          className="image-div"
                          style={{
                            height: "200px",
                            backgroundColor: "#fff",
                            borderTopRightRadius: "5px",
                            WebkitBorderTopLeftRadius: "5px",
                          }}
                        >
                          <ImageSelector
                            file={chatFile}
                            setFile={setChatFile}
                          />
                        </div>
                      )}
                      <textarea
                        value={newMessageText}
                        autoFocus={true}
                        onPaste={handlePaste}
                        onDrop={handleDrop}
                        onChange={handleAreaChange}
                        onKeyDown={handleAreaKeyDown}
                        onKeyUp={handleAreaKeyUp}
                        className="send-message-area"
                        placeholder="Message..."
                      />
                      <div className="send-icon-parent">
                        {isSendingMessage ? (
                          <IconButton>
                            <CircularProgress
                              style={{ width: 22, height: 22 }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={
                              isSendingMessage ||
                              (newMessageText.trim() == "" && !chatFile)
                            }
                            onClick={(e) => {
                              !isEditingMessage && handleSendMessage(e);
                              isEditingMessage && handleSendEditMessage(e);
                            }}
                          >
                            <SendIcon style={{ width: 22, height: 22 }} />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-1 pl-0">
                    <div className="d-flex align-items-center justify-content-center">
                      {!isEditingMessage && (
                        <label htmlFor="icon-button-file" className="m-0">
                          <input
                            className="d-none"
                            accept="image/png,image/jpg,image/jpeg"
                            id="icon-button-file"
                            type="file"
                            value=""
                            onChange={(e) => setChatFile(e.target.files[0])}
                          />
                          <Tooltip arrow title="upload image">
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                            >
                              <PhotoSizeSelectActualIcon />
                            </IconButton>
                          </Tooltip>
                        </label>
                      )}
                      {isEditingMessage && (
                        <Tooltip arrow title="Cancel">
                          <IconButton
                            color="primary"
                            component="span"
                            onClick={() => {
                              setNewMessageText("");
                              setIsEditingMessage(false);
                            }}
                          >
                            <Iconify
                              icon="iconoir:cancel"
                              sx={{
                                height: "25px",
                                width: "25px",
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {loadingMessageList && (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
}

export default MessageContainer;
