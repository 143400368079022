import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { MuiDrawer, Iconify } from "..";

const FiltersDrawer = ({
  open,
  onClose,
  onChange,
  priorityFilters,
  statusFilters,
  clearFilter,
  selectedFilters,
}) => {
  const handleClose = () => {
    onClose();
  };

  const onClear = () => {
    clearFilter();
  };
  const handleChange = (e, item, type) => {
    onChange(e, item, type);
  };
  return (
    <>
      <MuiDrawer
        isOpen={open}
        onClose={handleClose}
        paperProps={{ width: { xs: 1, sm: 320 } }}
        title="Task Filters"
        bottomComponent={<FiltersFooter onClear={onClear} />}
      >
        <Stack spacing={3}>
          <div>
            <Typography fontWeight="bold" sx={{ mb: 0.5 }}>
              Status
            </Typography>
            <FormGroup>
              {statusFilters.map((item, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      name={item.lookup}
                      checked={
                        selectedFilters.task_status.findIndex(
                          (option) => option == item.value
                        ) >= 0
                          ? true
                          : false
                      }
                      onChange={(e) => handleChange(e, item, "status")}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </div>
          <div>
            <Typography fontWeight="bold" sx={{ mb: 0.5 }}>
              Priority
            </Typography>
            <FormGroup>
              {priorityFilters.map((item, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      name={item.lookup}
                      checked={
                        selectedFilters.priority.findIndex(
                          (option) => option == item.value
                        ) >= 0
                          ? true
                          : false
                      }
                      onChange={(e) => handleChange(e, item, "priority")}
                    />
                  }
                  label={item.label}
                />
              ))}
            </FormGroup>
          </div>
        </Stack>
      </MuiDrawer>
    </>
  );
};

export default FiltersDrawer;
const FiltersFooter = ({ onClear }) => {
  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ pl: 3, pr: 3, pt: 2, pb: 1 }}>
        <Button
          fullWidth
          size="large"
          type="submit"
          color="inherit"
          variant="outlined"
          className="border"
          onClick={onClear}
          startIcon={<Iconify icon={"material-symbols:clear-all-rounded"} />}
        >
          Clear All
        </Button>
      </Box>
    </Box>
  );
};
