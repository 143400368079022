import {
  Container,
  Box,
  Stack,
  IconButton,
  Button,
  TextField,
  Typography,
  Select,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import {
  MuiAutoComplete,
  MuiMultiAutocomplete,
  NavBackHeader,
  Page,
  RichEditor,
} from "src/components";

import { ALLOWED_IMAGE_TYPES } from "src/constants";
import {
  get_from_localStorage,
  get_zone_date,
  set_to_localStorage,
  upload_image,
} from "src/utils";
import * as Yup from "yup";
import { useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  _create_workspace_project,
  _project_template_list,
  _workspace_team,
} from "src/DAL";
import { useAppContext } from "src/hooks";
import moment from "moment";
import ImageSelector from "src/components/ImageSelector";
import { _workspace_admin_detail } from "src/DAL/workspace";
// ---------------------------------------------------------
const common_button_size = "5rem";
const CreateWorkspaceProject = () => {
  const {
    dispatch_get_user_profile,
    socketEmit,
    slugProjectsData,
    setSlugProjectsData,
    setLimitReachedDialogOpen,
    dispatch_get_plan_limits,
    dispatch_get_current_plan,
    dispatch_set_plan_limits,
  } = useAppContext();
  const user = dispatch_get_user_profile();
  const navigate = useNavigate();
  const [formInputs, setFormInputs] = useState({
    image: "",
    title: "",
    startDate: null,
    endDate: null,
    team: [],
    description: "",
  });
  const location = useLocation();
  const { state } = location;
  console.log(state, "AScasjcashcjkasc");
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);
  const [team, setTeam] = useState([]);
  const editorRef = useRef(null);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [workspaceAdminDetail, setWorkspaceAdminDetail] = useState();
  const WorkspaceSchema = Yup.object().shape({
    image: Yup.string(),
    title: Yup.string()
      .min(1, "Workspace Title too short!")
      .required("Workspace is required"),
    description: Yup.string(),
    // startDate: Yup.string()
    //   .min(1, "Invalid start date!")
    //   .notOneOf(["Invalid date", null], "Invalid start date!")
    //   .required("Start date is required"),
    // endDate: Yup.string()
    //   .min(1, "Invalid end date!")
    //   .notOneOf(["Invalid date", null], "Invalid end date!")
    //   .required("End date is required"),
    team: Yup.array(),
  });
  const handleAutocompleteChange = (value) => {
    console.log(value, "val");
    setAutoCompleteValue(value);
    setFormInputs({ ...formInputs, ["team"]: value });
  };
  const getWorkspaceTeam = async () => {
    console.log(state, "state");
    if (!state) {
      navigate("/workspaces", { replace: true });
      return;
    }
    const result = await _workspace_team(null, state._id);
    const getModified = (data) => {
      let modified = data.map((item) => {
        return {
          ...item,
          label: item.first_name + " " + item.last_name,
          value: item,
        };
      });
      return modified.filter(
        (item) =>
          String(item.role) !== "admin" &&
          String(item.user_id) !== dispatch_get_user_profile().user_id
      );
    };
    if (result.code === 200) {
      setLoading(false);
      console.log(result, "team-res");
      setTeam(getModified(result.team_list.customer_profile));
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleChange = async (e) => {
    const { target } = e;
    setFormInputs({ ...formInputs, [target.name]: target.value });
  };
  const handleFileChange = async (e) => {
    if (processing) {
      return;
    }
    let file = e.target.files[0];
    const { type } = file;
    let valid_type = false;
    console.log(file, "target-file");
    ALLOWED_IMAGE_TYPES.map((item, i) => {
      if (item === String(type)) {
        valid_type = true;
      }
    });
    if (!valid_type) {
      enqueueSnackbar("Please choose an image", { variant: "error" });
      return;
    }
    setFile(file);
    setFormInputs({ ...formInputs, ["image"]: URL.createObjectURL(file) });
    //
  };
  const getImgSrc = (path) => {
    return path;
  };
  const getMinDate = (format) => {
    let today = moment();
    const with_zone = get_zone_date(today, format);
    return moment(with_zone);
  };

  const getFormattedDate = (date, format) => {
    if (!date) {
      return "";
    }
    return get_zone_date(date, format);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state || !state.role) {
      return;
    }
    if (state.role == "admin") {
      if (
        dispatch_get_plan_limits().project_count >=
        dispatch_get_current_plan().project_allow
      ) {
        setLimitReachedDialogOpen(true);
        return;
      }
    } else {
      if (
        workspaceAdminDetail.count_data.project_count >=
        workspaceAdminDetail.current_plans.project_allow
      ) {
        enqueueSnackbar(
          "Project limit for the admin of this workspace reached.",
          { variant: "error" }
        );
        return;
      }
    }

    let invalid = false;
    await WorkspaceSchema.validate(formInputs).catch((err) => {
      if (err) {
        err.errors.map((error) => {
          enqueueSnackbar(error, { variant: "error" });
          invalid = true;
        });
        return;
      }
    });
    if (invalid) {
      return;
    }
    const start_date = formInputs.startDate;
    const end_date = formInputs.endDate;
    let is_before = moment(start_date).isBefore(end_date);
    let is_same = moment(start_date).isSame(end_date);
    if (start_date && end_date) {
      if (is_same === false) {
        if (is_before === false) {
          enqueueSnackbar(
            "Start date should be greater or equal to end date!",
            {
              variant: "error",
            }
          );
          return;
        }
      }
    }
    let project_team = [];
    // if (
    //   String(state.role) === String("manager") ||
    //   state.allow_add_project === true
    // ) {
    //   // project_team.push(String(state.team_id));
    //   project_team.push(state.team.find(member=>member.email==state.user.email))
    // }
    // team
    //   .filter(
    //     (member) => member.user_id !== dispatch_get_user_profile().user_id
    //   )
    //   .map((member) => {
    //     if (member.role == "manager") {
    //       project_team.push(member);
    //     }
    //   });
    formInputs.team.forEach((item) => {
      project_team.push(item);
    });
    // const uniqueArray = project_team.reduce((accumulator, current) => {
    //   const isDuplicate = accumulator.find(
    //     (item) => item.user_id === current.user_id
    //   );
    //   if (!isDuplicate) {
    //     return [...accumulator, current];
    //   }
    //   return accumulator;
    // }, []);
    const data = {
      title: formInputs.title,
      workspace_id: state._id,
      team: project_team,
      description: formInputs.description,
      status: true,
      start_date: getFormattedDate(formInputs.startDate, "YYYY-MM-DD"),
      end_date: getFormattedDate(formInputs.endDate, "YYYY-MM-DD"),
      image: "",
      project_template_id: selectedTemplate,
      tag_color_list: [
        { id: "1", name: "", color_code: "#a83e32" },
        { id: "2", name: "", color_code: "#688c6c" },
        { id: "3", name: "", color_code: "#b05a65" },
      ],
    };
    // console.log(data, "create-proj-req");
    // return;
    setProcessing(true);
    if (file) {
      const result = await upload_image(file);
      if (result.code === 200) {
        data.image = result.path;
      }
    }
    console.log(data, "create-proj-req");
    const result = await _create_workspace_project(data);
    if (result.code === 200) {
      console.log(result, "create-proj-res");
      setProcessing(false);
      enqueueSnackbar("Project Created Successfully", { variant: "success" });
      let temp = get_from_localStorage("projects");
      let newProject = result.project;
      temp.projects.push({
        ...newProject,
        role: state.role,
        query_field: newProject.title,
        completed_task: 0,
        pending_task: 0,
        total_task: 0,
      });
      set_to_localStorage("projects", temp);
      let receiver = [];
      result.project.team.map((member) => {
        if (member.user_id !== dispatch_get_user_profile().user_id) {
          receiver.push(member.user_id);
        }
      });
      setSlugProjectsData(temp);
      socketEmit("send_notification_to_all", {
        receiver,
        data: result.project,
        message: `${dispatch_get_user_profile().first_name} ${
          dispatch_get_user_profile().last_name
        } created a new Project`,
        link: `${window.location.origin}/workspaces/projects/${result.project._id}`,
      });
      navigate(-1);
      dispatch_set_plan_limits({
        ...dispatch_get_plan_limits(),
        project_count: dispatch_get_plan_limits().project_count + 1,
      });
    } else {
      setProcessing(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getProjectTemplates = async () => {
    try {
      const result = await _project_template_list();
      if (result.code == 200) {
        setTemplates(result.project_template_list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(
        error,
        " catched while adding the project and fetching the templates"
      );
    }
  };
  const getWorkspaceAdminDetail = async () => {
    try {
      const result = await _workspace_admin_detail(state?.user_id);
      setWorkspaceAdminDetail(result);
    } catch (error) {
      enqueueSnackbar("Something went wrong!", { variant: "error" });
      console.log(error, " catched while fetching admin details");
    }
  };
  useEffect(() => {
    if (!state) {
      navigate("/", { replace: true });
    }
  }, []);
  useLayoutEffect(() => {
    getWorkspaceTeam();
    getProjectTemplates();
    state?.role !== "admin" && getWorkspaceAdminDetail();
  }, []);
  // ---------------------------------------------------------
  return (
    <Page title="Create Project">
      <Container maxWidth="xl" sx={{ pb: 5 }}>
        <div className="mb-4">
          <NavBackHeader heading="Create Project" />
        </div>
        <Container maxWidth="md">
          <Box component="form" autoComplete="off" onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack spacing={3}>
                {/* {formInputs.image ? (
                  <Container maxWidth="sm">
                    <div className="centered-row">
                      <img
                        width="50%"
                        height="50%"
                        src={getImgSrc(formInputs.image)}
                        alt="user-image"
                      />
                    </div>
                  </Container>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      height: "58px",
                      border: "dashed 1px rgb(113,128,141)",
                      borderRadius: "7px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgb(113,128,141)" }}
                      textAlign="center"
                    >
                      Please choose an image. (optional)
                    </Typography>
                  </div>
                )}
                <div className="centered-row">
                  <label htmlFor="icon-button-file">
                    <input
                      className="d-none"
                      accept="image/png,image/jpg,image/jpeg"
                      id="icon-button-file"
                      type="file"
                      value=""
                      onChange={handleFileChange}
                    />
                    <Button
                      sx={{ width: common_button_size }}
                      variant="contained"
                      aria-label="upload picture"
                      component="span"
                      disabled={processing}
                    >
                      Upload
                    </Button>
                  </label>
                </div> */}
                <ImageSelector
                  setFile={setFile}
                  placeHolder="Choose or Drop Image (optional). Recommended aspect ratio 1:1"
                />
                {/* image done */}
              </Stack>
              <Stack spacing={2} direction="row">
                <FormControl fullWidth>
                  <TextField
                    label="Project Title"
                    required
                    name="title"
                    variant="outlined"
                    value={formInputs.title}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Template
                  </InputLabel>
                  <Select
                    value={selectedTemplate}
                    onChange={(e) => setSelectedTemplate(e.target.value)}
                    label="Select Template"
                  >
                    {templates.map((template) => {
                      return (
                        <MenuItem key={template._id} value={template._id}>
                          {template.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Stack>

              {/* <TextField
                label="Project Description"
                multiline
                maxRows={5}
                name="description"
                variant="outlined"
                value={formInputs.description}
                onChange={handleChange}
              /> */}
              <RichEditor
                editorRef={editorRef}
                value={formInputs.description}
                setValue={(val) =>
                  setFormInputs({
                    ...formInputs,
                    description: val,
                  })
                }
              />
              <Stack direction="row" spacing={3}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="Start Date"
                    inputFormat="DD-MMM-YYYY"
                    minDate={getMinDate("YYYY-MM-DD")}
                    // rifmFormatter={(date) =>
                    //   getFormattedDate(date, "DD-MMM-YYYY")
                    // }
                    value={formInputs.startDate}
                    onChange={(newValue) =>
                      setFormInputs({ ...formInputs, ["startDate"]: newValue })
                    }
                    renderInput={(params) => (
                      // console.log(params,"params")
                      <TextField
                        fullWidth
                        name="startDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DesktopDatePicker
                    label="End Date"
                    inputFormat="DD-MMM-YYYY"
                    minDate={getMinDate("YYYY-MM-DD")}
                    rifmFormatter={(date) =>
                      getFormattedDate(date, "DD-MMM-YYYY")
                    }
                    value={formInputs.endDate}
                    onChange={(newValue) => {
                      if (!formInputs.startDate) {
                        setFormInputs({
                          ...formInputs,
                          ["endDate"]: newValue,
                          ["startDate"]: new Date(),
                        });
                      } else {
                        setFormInputs({ ...formInputs, ["endDate"]: newValue });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        name="endDate"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MMM-YYYY",
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
              <MuiMultiAutocomplete
                value={autoCompleteValue}
                onChange={handleAutocompleteChange}
                options={team}
                disabled={loading}
                label="Team Members"
              />
              <div className="centered-row">
                <LoadingButton
                  type="submit"
                  sx={{ width: common_button_size }}
                  loading={processing}
                  variant="contained"
                >
                  Create
                </LoadingButton>
              </div>
            </Stack>
          </Box>
        </Container>
      </Container>
    </Page>
  );
};

export default CreateWorkspaceProject;
